import React from 'react'
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { VStack } from '@chakra-ui/layout';
import { Helmet } from 'react-helmet';

import DrawButton from '../../../../../components/DrawButton'
import Text from '../../../../../components/Text'
import Box from '../../../../../components/Box'
import MultiChoice from '../../../../../components/MultiChoice'
// import DrawArrowButton from '../../../../components/DrawArrowButton';
import FullpageHeight from '../../../../../components/FullpageHeight';
// import { responsive } from '../../components/ThemeProvider/theme'

const Relation = ({ pageContext }) => {
  const { control } = useFormContext();
  const { isSubmitting } = useFormState({ control });
  const formattedRelation = pageContext.relations.map(([value, memo]) => ({ value, label: memo ? `${value}（${memo}）` : value }))
  return (
    <FullpageHeight maxWidth="container.sm" alignItems="center" py="8" as={VStack} justifyContent="center">
      <Helmet>
        <title>選擇關係 - 愛之語測驗愛情版</title>
      </Helmet>
      {/* <DrawArrowButton color="love.text" onClick={() => window.history.back()} /> */}
      <Text.LoveP fontSize="1.125rem">請問你和對方的關係是？</Text.LoveP>
      <Box py="6" w="full">
        <Controller
          name="relation"
          control={control}
          defaultValue={false}
          rules={{ required: true }}
          render={({ field }) => (
            <MultiChoice
              spacing="4"
              options={formattedRelation}
              optionEle={DrawButton}
              isDisabled={isSubmitting}
              autoSubmit
              {...field}
            />
          )}
        />
      </Box>
    </FullpageHeight>
  )
}

export default Relation
