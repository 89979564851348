import { Box, Stack } from '@chakra-ui/layout'
import { useRadio, useRadioGroup } from '@chakra-ui/radio'
import React, { createElement, forwardRef, useMemo, useState } from 'react'
import handleAutoSubmit from '../utils/handleAutoSubmit'

const RadioCard = forwardRef(({ as, onClick, ...props }, ref) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" width="full" ref={ref} onClick={onClick}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        as={as}
        mx="auto"
      >
        {props.children}
      </Box>
    </Box>
  )
})

const MultiChoice = forwardRef(({
  options = [],
  name,
  defaultValue,
  onChange,
  onBlur,
  optionEle = 'div',
  value,
  autoSubmit,
  isDisabled,
  ...props
}, ref) => {
  const [lock, setLock] = useState()
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
    onBlur,
    value,
    isDisabled,
  })

  const group = getRootProps()
  const optionElement = useMemo(() => p => createElement(optionEle, { ...p, as: 'div' }), [optionEle])
  const handleClick = (e) => {
    // e.preventDefault()
    if (autoSubmit && !(isDisabled || lock)) {
      handleAutoSubmit(e.target)
      setTimeout(() => setLock(true))
    }
  }

  return (
    <Stack w="full" spacing="1.875em" {...props} {...group} ref={ref}>
      {options.map((val) => {
        const [v, l] = typeof val === 'object' ? [val.value, val.label] : [val, val]
        const radio = getRadioProps({ value: v })
        return (
          <RadioCard
            key={v}
            as={optionElement}
            {...radio}
            isDisabled={isDisabled || lock}
            onClick={handleClick}
          >
            {l}
          </RadioCard>
        )
      })}
    </Stack>
  )
}
)

export default MultiChoice
